// #region material theming
// TODO re-evaluate this region

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$ex-blue: (
  50: #ffffff,
  100: #dde6f3,
  200: #b4c9e4,
  300: #7fa3d1,
  400: #6992c9,
  500: #5282c1,
  600: #4072b4,
  700: #38649d,
  800: #305687,
  900: #284770,
  A100: #ffffff,
  A200: #dde6f3,
  A400: #6992c9,
  A700: #38649d,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

$ex-green: (
  50: #e5f4f3,
  100: #bee4e1,
  200: #93d3cd,
  300: #67c1b8,
  400: #47b3a9,
  500: #26a69a,
  600: #229e92,
  700: #1c9588,
  800: #178b7e,
  900: #0d7b6c,
  A100: #adfff3,
  A200: #7affec,
  A400: #47ffe4,
  A700: #2dffe0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$ex-orange: (
  50: #ffffff,
  100: #fff7f4,
  200: #fecdbd,
  300: #fc9977,
  400: #fc8259,
  500: #fb6c3b,
  600: #fa551d,
  700: #f44205,
  800: #d63a04,
  900: #b83204,
  A100: #ffffff,
  A200: #fff7f4,
  A400: #fc8259,
  A700: #f44205,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

$crm-primary: mat.define-palette($ex-blue);
$crm-accent: mat.define-palette($ex-green, A200, A100, A400);

// The warn palette is optional (defaults to red).
$crm-warn: mat.define-palette($ex-orange);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$crm-theme: mat.define-light-theme(
  (
    color: (
      primary: $crm-primary,
      accent: $crm-accent,
      warn: $crm-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($crm-theme);

// #endregion material theming

@import '~firebaseui/dist/firebaseui.css';

app-root {
  height: 100%;
}

.kt-page {
  height: 100%;
}

#kt_wrapper {
  min-height: 100vh;
}

/* -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */
